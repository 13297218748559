<!--
data: 
- title: text
- subTitle: text
- text: text
- images: list (image: image, alt: text, link: text)
- bgText: text
- backgroundImage: image,
- buttons: list (buttonText: text, url: text)
- style: select (light: text, dark: text, color: text)
-->
<template>
    <div class="cs-block cs-showcases-circles-block" :class="styleClass">
        <div class="cs-block-base">
            <div class="container">
                <h2 class="cs-title" v-if="data.title">{{data.title}}</h2> 
                <h3 class="cs-sub-title" v-if="data.subTitle">{{data.subTitle}}</h3>
                <span class="cs-text" v-if="data.text" v-html="data.text"></span>

                <div class="image-container" v-if="data.images && Object.keys(data.images).length" :class="(data.buttons && Object.keys(data.buttons).length) ? '' : 'mb-0'">
                    <div class="image" v-for="image in data.images">
                        <a :href="image.url"><img :src="image.image"></a>
                    </div>
                </div>

                <div v-if="data.buttons && Object.keys(data.buttons).length">
                    <Buttons :data="data.buttons" :cs_style="data.style" />    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from '../libs/common-fn';

    export default {
        name: 'ShowcaseCircles',
        props: {
            data: {
                type: Object,
                default: () => {}
            }
        },
        components: {
            Buttons: () => import("../components/CSUtilsButtons.vue"),
        },
        computed: computed('ShowcaseCircles')
    } 
</script>

<style lang="scss" scoped>
    @import '../styles/main.scss';
    .cs-showcases-circles-block{
        .image-container {
            column-count: 4;
            column-gap: 20px;
            margin: 15px 0 30px;
            @media (max-width: 991px) {
                column-count: 3;
            }
            @media (max-width: 767px) {
                column-count: 2;
            }
            @media (max-width: 500px) {
                column-count: 1;
            }
            .image {
                margin: 0;
                display: grid;
                grid-template-rows: 1fr auto;
                break-inside: avoid;
                margin-bottom: 20px;
                &:last-child {
                    @media (max-width: 500px) {
                        margin-bottom: 0;
                    }
                }
                a {
                    text-decoration: none;
                    grid-row: 1 / -1;
                    grid-column: 1;
                    img {
                        width: 100%;
                        height: 170px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
</style>
